import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Brian Kwong | Software Engineer', // e.g: 'Name | Developer'
  lang: 'en', // e.g: en, es, fr, jp
  description: 'Fullstack & Web 3.0 Engineer', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: 'Hi, my name is',
  name: 'Brian',
  subtitle: `I'm a Software Engineer. 💻`,
  cta: 'Know more about me',
  img: 'hero_square.jpg',
};

// ABOUT DATA
export const aboutData = {
  img: 'profile_square.jpg',
  title1: 'Full-Stack Blockchain Developer',
  paragraph1:
    '• 4+ years of experience in full-stack development with React.js, Node.js, TypeScript, and Solidity',
  paragraph2:
    '• 3+ years working in the cutting-edge Web3 space, building decentralized applications',
  paragraph3: '• Won the Best Developer Tool Award at ETHGlobal Waterloo 2023',
  title2: 'Senior Software Developer @ OpenZeppelin',
  paragraph4:
    '• Currently contributing to the Forta Protocol, an AI-powered security monitoring solution for blockchain apps',
  paragraph5:
    '• Responsibilities include developing new features, integrating with Web3 protocols, and ensuring robust architecture',
  title3: 'Blockchain Expertise',
  paragraph6:
    '• Proven track record in Crypto and NFT projects, including the Crypto.com NFT Marketplace',
  paragraph7:
    '• Strong problem-solving skills with a passion for exploring emerging blockchain technologies',
  paragraph8: '• Keen interest in driving innovation and mainstream adoption of Web3 solutions',
  resume: '../../briankwong-resume.pdf', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    // img: 'project.jpg',
    title: 'Token Swap',
    info: 'An application for swapping ETH and USDC tokens on Ethereum using Uniswap V2 protocol.',
    info2: 'React, TypeScript, Uniswap Protocol',
    repo: 'https://github.com/briankwong65/token-swap', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    // img: 'project.jpg',
    title: 'Hyperlane VS Code',
    info: 'Best Developer Tool Award from Hyperlane in ETHGlobal Waterloo 2023',
    info2: 'React, TypeScript',
    url: 'https://ethglobal.com/showcase/hyperlane-vs-code-t3bbz',
    repo: 'https://github.com/ericglau/hyperlane-vscode', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    // img: 'project.jpg',
    title: 'Fractional BAYC Project',
    info: 'An Etheterum NFT (ERC-1155) IP DAO',
    info2: 'Solidity, React, TypeScript',
    // url: 'https://etherscan.io/address/0x0c24f4224dd1abdaa907bacc26679f7e06a8a442',
    // repo: 'https://github.com/briankwong65/jff-bayc', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    // img: 'project.jpg',
    title: 'Solana Genesis Pass',
    info: 'A Solana NFT DAO sold out in 25 minutes!',
    info2: 'Candy Machine, React, TypeScript',
    // url: 'https://investman.io',
    // repo: 'https://github.com/briankwong65/investman-minting-page', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    // img: 'project.jpg',
    title: 'Firechat 🔥',
    info: 'An online chat room for anyone to talk!',
    info2: 'React, Node.js, TypeScript, Firebase',
    url: 'https://firechat.briankwong.me',
    repo: 'https://github.com/briankwong65/firechat', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    // img: 'project.jpg',
    title: 'Easy Clinic 🩺',
    info:
      'A simple hybrid mobile app for clinic to record diagnosis records with login authentication and a user-friendly UI.',
    info2: 'React, Node.js, MySQL',
    // url: '',
    repo: 'https://github.com/briankwong65/easy-clinic', // if no repo, the button will not show up
  },
  // {
  //   id: nanoid(),
  //   img: 'project.jpg',
  //   title: '',
  //   info: '',
  //   info2: '',
  //   url: '',
  //   repo: 'https://github.com/cobidev/react-simplefolio', // if no repo, the button will not show up
  // },
];

// CONTACT DATA
export const contactData = {
  cta: '',
  btn: '',
  email: 'briankwong98@gmail.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    // {
    //   id: nanoid(),
    //   name: 'twitter',
    //   url: '',
    // },
    // {
    //   id: nanoid(),
    //   name: 'codepen',
    //   url: '',
    // },
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/brian-kwong/',
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/briankwong65',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
